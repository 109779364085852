<template>
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M34 23.5L25.75 31.75L22 28"  stroke-linecap="round" stroke-linejoin="round"/>
    <circle cx="28" cy="28" r="26.5" />
  </svg>
</template>

<script>
export default {
  name: 'CircleCheckboxIcon'
}
</script>
